<template>
    <div>
        <v-row class=" ml-6 mr-6 mt-4">
            <div class="search-container d-flex d-flex-row">
                <div v-for="item,i in filterList" :key="i">
                    <v-chip
                        class="mt-2 mb-2 mr-1"
                        outlined
                        label
                        color="primary"
                        close
                        @click:close="onRemoveFilter(item)"
                        v-if="item.is_selected"
                    >
                        <span class="mr-1">{{ item.value }}</span> 
                        <span v-if="item.selected_operator == 'equal'">{{ ' = ' }}</span>
                        <span v-else-if="item.selected_operator == 'not_equal'">{{ ' != ' }}</span>
                        <span v-else-if="item.selected_operator == 'range'">{{ ` &#8596; ` }}</span> 
                        <span class="ml-1">{{  item.selected_value_name ? item.selected_value_name : '' }}</span>
                    </v-chip>
                </div>
                <v-text-field
                    ref="searchTextField"
                    class="mx-2"
                    flat
                    placeholder="Select one of the available options below"
                    @click="onShowMenuClick"
                ></v-text-field>
                <v-menu
                    v-model="showMenu"
                    :position-x="menuPositionX"
                    :position-y="menuPositionY"
                    absolute
                    offset-y
                    :close-on-content-click="false"
                >
                    <v-list v-if="showMenuSelection && activeSelection.length == 0">
                        <div v-for="filter,i in filterList" :key="i">
                            <v-list-item v-if="!filter.is_selected" @click="onSelectFilter(filter)">
                                <v-list-item-title>{{ filter.value }}</v-list-item-title>
                            </v-list-item>
                        </div>
                    </v-list>
                    <v-list v-else-if="activeSelection && !activeSelection.selected_operator">
                        <div v-for="operator,i in activeSelection.operators" :key="i">
                            <v-list-item v-if="operator" @click="onOperatorSelect(i)">
                                <span v-if="i.toLowerCase() == 'equal'">{{ '=' }}</span>
                                <span v-else-if="i.toLowerCase() == 'not_equal'">{{ '!=' }}</span>
                                <span v-else>{{ `&#8596;` }}</span>
                            </v-list-item>
                        </div>
                    </v-list>
                    <v-list v-else-if="activeSelection.selected_operator">
                        <div v-if="activeSelection.key === 'basis'">
                            <v-list-item  v-for="value,i in basisList" :key="i" @click="onValueSelect(activeSelection.key,value)">
                                <span>{{ value.text }}</span>
                            </v-list-item>
                        </div>
                        <div v-else-if="activeSelection.key === 'de_account_id'">
                            <v-list-item  v-for="value,i in deAccountList" :key="i" @click="onValueSelect(activeSelection.key,value)">
                                <span>{{ value.text }}</span>
                            </v-list-item>
                        </div>
                        <div v-else-if="activeSelection.key === 'contact'">
                            <div>
                                <v-list-item>
                                    <v-autocomplete
                                        id="formdata-customer"
                                        v-model="selectedContact"
                                        :loading="isContactDataLoading"
                                        :items="contactList"
                                        :search-input.sync="searchContactText"
                                        item-text="name"
                                        item-value="id"
                                        return-object
                                        clearable
                                        dense
                                        solo
                                        flat
                                        outlined
                                        :rules="[(v) => !!v || 'Field required']"
                                        class="app-theme-input-border"
                                        hide-details="auto"
                                        label="Select Customer"
                                        @change="onSelectContact"
                                        :menu-props="{ bottom: true, offsetY: true, contentClass: 'accounting-lists-items' }"
                                    >
                                    </v-autocomplete>
                                </v-list-item>
                            </div>
                        </div>
                        <div v-else-if="activeSelection.key === 'report_at'">
                            <div>
                                <v-list-item>
                                    <v-date-picker
                                        v-model="dates"
                                        :range="activeSelection.selected_operator === 'range' ? true : false"
                                        @change="onDateSelect"
                                    ></v-date-picker>
                                </v-list-item>
                            </div>
                        </div>
                        <div v-else-if="activeSelection.key === 'year'">
                            <div>
                                <v-list-item>
                                    <v-autocomplete
                                        id="formdata-year"
                                        v-model="year"
                                        :items="yearList"
                                        clearable
                                        dense
                                        solo
                                        flat
                                        outlined
                                        class="app-theme-input-border"
                                        hide-details="auto"
                                        label="Select Year"
                                        @change="onYearSelect"
                                    >
                                    </v-autocomplete>
                                </v-list-item>
                            </div>
                        </div>
                    </v-list>
                </v-menu>
                
                <v-btn
                    @click="onSearch"
                    class="text-capitalize"
                    color="btn-blue"
                    height="38"
                >Search</v-btn>
            </div>
        </v-row>	
    </div>
</template>
<script>
import AccountingService from '../services/accounting.service';
import accountingGlobalMethods, { apiErrorMessage, debounce } from '../utils/accountingGlobalMethods';

export default {
    name: 'ReportSearch',
    components: {
        // 
    },
    props: {
        filters: {},
    },
    data() {
        return {
            filterList: this.filters,
            activeSelection: [],
        deAccountList: [],
        basisList: [
                { text: 'Accrual', value: 'accrual' },
                { text: 'Cash', value: 'cash' },
            ],
            searchContactText: '',
            showMenuSelection: true,
            showMenu: false,
            search: '',
            menuPositionX: 0,
            menuPositionY: 0,
            selectedContact: null,
            contactList: [],
            isContactDataLoading: false,
            dates: null,
            year: null,
        }
    },

    computed: {
        yearList: function(){
            let years = [];
            let startYear = new Date().getFullYear();
            for (var i = startYear; i > startYear - 10; i--) {
                years.push(i);
            }
            return years;
        },
    },

    created() {
        this.getDoubleEntries();
        this.searchContact();
    },

    watch: {
        searchContactText: debounce(function(val) {
            this.searchContact(val);
        }, 400),

        activeSelection(val){
            if(val.length == 0){
                this.showMenuSelection = true;
            }
        },
    },

    methods: {
        ...accountingGlobalMethods,

        onShowMenuClick(e){
            e.preventDefault()
            this.showMenu = false
            this.menuPositionX = e.clientX
            this.menuPositionY = e.clientY
            this.$nextTick(() => {
                this.showMenu = true
            });
        },

        onSelectFilter(filter){
            this.activeSelection = filter;
            this.showMenuSelection = false;
            Object.keys(this.filterList).forEach((k) => {
                if(this.filterList[k].key == filter.key){
                    this.filterList[k].is_selected = true;
                }
            });
        },

        onOperatorSelect(operator){
            if(operator){
                let op = operator.toLowerCase();
                this.activeSelection = {
                    ...this.activeSelection,
                    selected_operator: op
                };
                Object.keys(this.filterList).forEach((k) => {
                    if(this.filterList[k].key == this.activeSelection.key){
                        this.filterList[k].selected_operator = op;
                    }
                });
            }
        },

        onValueSelect(key,value){
            if(key && value){
                Object.keys(this.filterList).forEach((k) => {
                    if(this.filterList[k].key == key){
                        this.filterList[k].selected_value = value.value;
                        this.filterList[k].selected_value_name = value.text;
                    }
                });
                this.activeSelection = [];
            }
        },

        onRemoveFilter(item){
            Object.keys(this.filterList).forEach((k) => {
                if(this.filterList[k].key == item.key){
                    this.filterList[k].selected_value = null;
                    this.filterList[k].selected_value_name = null;
                    this.filterList[k].selected_operator = null;
                    this.filterList[k].is_selected = false;
                }
            });

            if(this.activeSelection.key === item.key){
                this.activeSelection = [];
            }
        },

        onSelectContact(contact){
            Object.keys(this.filterList).forEach((k) => {
                if(this.filterList[k].key == this.activeSelection.key){
                    this.filterList[k].selected_value = contact.id;
                    this.filterList[k].selected_value_name = contact.name;
                }
            });
            this.activeSelection = [];
            this.searchContactText = '';
        },

        onDateSelect(value){
            if(this.activeSelection.key === 'report_at'){
                if(Array.isArray(value)){
                    Object.keys(this.filterList).forEach((k) => {
                        if(this.filterList[k].key == this.activeSelection.key){
                            this.filterList[k].selected_value = value;
                            this.filterList[k].selected_value_name = value[0] + ' to ' + value[1];
                        }
                    });
                    this.activeSelection = [];

                }else{
                    Object.keys(this.filterList).forEach((k) => {
                        if(this.filterList[k].key == this.activeSelection.key){
                            this.filterList[k].selected_value = value;
                            this.filterList[k].selected_value_name = value;
                        }
                    });
                    this.activeSelection = [];
                }
            }   
        },

        onYearSelect(value){
            Object.keys(this.filterList).forEach((k) => {
                if(this.filterList[k].key == this.activeSelection.key){
                    this.filterList[k].selected_value = value;
                    this.filterList[k].selected_value_name = value;
                }
            });
            this.activeSelection = [];
        },

        onSearch(){
            let args = '';
            if(this.filterList){
                Object.keys(this.filterList).forEach((k) => {
                    if(this.filterList[k].is_selected && this.filterList[k].selected_value){
                        if(this.filterList[k].key === 'report_at'){
                            if(this.filterList[k].selected_operator == "range" && Array.isArray(this.filterList[k].selected_value)){
                                const isSequenceValid = new Date(this.filterList[k].selected_value[0]) < new Date(this.filterList[k].selected_value[1]);
                                if(isSequenceValid){
                                    args += this.filterList[k].key + '>=' + this.filterList[k].selected_value[0] + ' ';
                                    args += this.filterList[k].key + '<=' + this.filterList[k].selected_value[1] + ' ';
                                }else{
                                    args += this.filterList[k].key + '>=' + this.filterList[k].selected_value[1] + ' ';
                                    args += this.filterList[k].key + '<=' + this.filterList[k].selected_value[0] + ' ';
                                }
                            }else{
                                args += this.filterList[k].key + ':' + this.filterList[k].selected_value + ' ';
                            }
                        }else{
                            if(this.filterList[k].selected_operator == "not_equal"){
                                args += 'not ' + this.filterList[k].key + ':' + this.filterList[k].selected_value + ' ';
                            }else if(this.filterList[k].selected_operator == "range"){
                                // 
                            }else{
                                args += this.filterList[k].key + ':' + this.filterList[k].selected_value + ' ';
                            }
                        }
                    }
                });
            }
            this.search = args;
            this.$emit('toggle',args);
        },
          
        async getDoubleEntries() {
            try {
                const { data } = await AccountingService.getCoaAccountsAndDefaults();
                const de = data.data.data;
                const options = de.accounts;
                const _data = [];
                Object.keys(options).forEach((key) => {
                    _data.push({ header: key });
                    Object.keys(options[key]).forEach((k) => {
                        _data.push({ text: options[key][k], value: parseInt(k) });
                    });
                });

                this.deAccountList = _data;
            } catch (error) {
                apiErrorMessage(error);
            }
        },

        async searchContact(searchText = null) {
            if (this.isContactDataLoading || !searchText) {
                return;
            }
            this.isContactDataLoading = true;
            try {
                const { data } = await AccountingService.getContacts({ page: 1, limit: 10, search: searchText });
                if(data.data){
                    const contacts = data.data;
                    this.contactList = contacts.data;
                }
            } catch (error) {
                apiErrorMessage(error);
            } finally {
                this.isContactDataLoading = false;
            }
        },
    }
};
</script>
<style>
   .v-select--is-menu-active{
        background: #ffffff !important;
   }
   .search-container{
        width: 100%;
   }
</style>